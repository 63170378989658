<template>
<div id="">

  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>
    <Loading v-if='loading'></Loading>
    <v-layout  wrap justify-center v-else>

      <v-flex xs8>
        <v-layout  wrap>
          <v-flex >

          <h2 class="display-1">{{$t("paymentPage.mra")}}</h2>
          <div class="official-info">
            <br>My Room Abroad - 出國租
            <br>VAT: 50895192
            <br>臺北市大同區南京西路163號2樓之39

          </div>
          <h1 class="ma-5 display-2">{{$t("paymentPage.invoice")}}</h1>
        </v-flex>

          <v-spacer></v-spacer>
          <v-img aspect-ratio=1 src="https://res.cloudinary.com/my-room-abroad/image/upload/q_auto,fl_lossy,f_auto/v1537411386/homepage/Logo_square.png" alt="My Room Abroad logo" height="100%"></v-img>
        </v-layout>
        <v-layout  wrap>

          <v-flex xs4>
            <h3>{{$t("paymentPage.invoiceFor")}}</h3>
            <p v-if="myUser.name!=undefined">{{myUser.name}}</p>
            <p v-else>{{myUser.userName}}</p>
            <h3>{{$t("paymentPage.dueDate")}}:</h3>
            <p>{{invoice.ts | date}}</p>
          </v-flex>
          <v-flex xs6>
            <h3>{{$t("paymentPage.payableTo")}}</h3>
            <p>
              {{$t("paymentPage.mra")}}
            </p>
            <div class="account-details border">
              <p>
                <div class="font-weight-bold">
                  Show this to the bank clerk:
                  <br> 我要將錢存進帳戶

                </div>
                <br>{{$t("paymentPage.accountHolder")}}: 出國租股份有限公司
                <br>Bank: Bank of Taiwan
                <br>Bank Code: 004
                <br>{{$t("paymentPage.accountNumber")}}: 019 001 163 903
              </p>
            </div>
            <p>Communication: {{invoice.ref}}</p>

          </v-flex>
          <v-flex xs2>
            <h3>{{$t("paymentPage.invoice")}} #</h3>
            <p>{{invoice.ref}}</p>

          </v-flex>

        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs9>
            <h2>{{$t("paymentPage.description")}}</h2>
            <br>
            <p>{{invoice.comment}}</p>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <h2>{{$t("paymentPage.amount")}}</h2>
            <br>
            <p>{{invoice.val}} TWD</p>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs9 class='text-xs-right'>
            <h2>{{$t("paymentPage.totalToPay")}}</h2>
          </v-flex>
          <v-flex xs3 class='text-xs-right' >
            <h2>
              {{invoice.val}} TWD
            </h2>
          </v-flex>
        </v-layout>
        </v-flex>

      </v-layout>

  </v-container>
</div>
</template>
<script>
import moment from 'moment'
import Loading from '../shared/Loading.vue'
import firebase from '../../FirebaseApp'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../store/types'


export default {
  name: "",
  data: () => ({
    loading:true,
    myUser:null,
  }),
  methods: {
    scrollUp() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }

  },
  firebase() {
    return {
      invoice: {
        source:firebase.database().ref('invoices/'+this.$route.params.id),
        asObject:true,
        readyCallback: function(){
          this.scrollUp()
          if (this.user.roleValue>60 || this.user.id == this.invoice.user){
            this.loading=false
            if (this.invoice.user!=this.user.id){
              axios.get('/users/'+this.invoice.user+'.json')
              .then(res=>{
                this.myUser=res.data
              })
            } else {
              this.myUser=this.user
            }
          } else {
            alert('This is not your invoice, please log in with the right account.')
            this.$router.push('/')
          }
        }
      }
    }
  },

  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),

  },
  created() {
    //do something after creating vue instance
    console.log('do the created');


  },
  components:{
    Loading,

  },
  filters:{
    date(item){
      return moment(item).add(1,'week').format('LL')
    }
  }
}
</script>
<style lang="scss" scoped>


</style>
